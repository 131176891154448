import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Input, Pagination, SelectPicker, Tag } from "rsuite";
import Swal from "sweetalert2";
import { APi } from "../Api/"; //
import ExportAdd from "../Components/Common/ExportAdd";
import Filter from "../Components/Common/Filter";
import Grid from "../Components/Grid";
import Responsive from "../Components/Responsive";
import AddEdit from "./AddEdit.component";
import { exportAddAtom } from "../exportAdd.atom";
import ReactPaginate from "react-paginate";
import GroupModel from "../GroupModel";
import { ENDPOINTS } from "../Api/enpoints";
import { ConfigState } from "../Atoms/configs.atom";
import axios from "axios";
import Visas from "./Visas";
export default function Groups(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [selectedVisa, setselectedVisa] = useState(0);

  const [filterModel, setfilterModel] = useState({
    q: "",
    page: 1,
    take: 20,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useState(new GroupModel());
  const [selected, setselected] = useState(0);
  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new GroupModel());
    setError("");
  };
  // useEffect(() => {

  // }, []);

  // API CALLS
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });

    APi.createAPIEndpoint(APi.ENDPOINTS.Group, filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };
  const save = () => {
    let msg = "";
    if (msg) setError(msg);
    else {
      setstate((prev) => {
        return { ...prev, loading: true };
      });
      if (model.id) {
        APi.createAPIEndpoint(APi.ENDPOINTS.Group)
          .update(model.id, model)
          .then((res) => {
            fetch();
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Élément a été bien modifié !",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      } else {
        APi.createAPIEndpoint(APi.ENDPOINTS.Group)
          .create(model)
          .then((res) => {
            fetch();
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Element a été bien ajouté !",
              showConfirmButton: false,
              timer: 1500,
            });
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      }
    }
  };

  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Group)
      .delete(id)
      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setError("");

    setmodel(data.find((el) => el.id == id));
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  const columns = [
    {
      value: "name",
      name: "إسم المجموعة ",
      render: (v) => <a>{v}</a>,
    },
    {
      value: "arrivalDate",
      name: "تاريخ القدوم",
      render: (v) => <b>{v ? v.substring(0, 10) : v}</b>,
    },
    {
      value: "departDate",
      name: "تاريخ المغادرة",
      render: (v) => <b>{v ? v.substring(0, 10) : v}</b>,
    },
    {
      value: "id",
      name: "-",
      render: (v) => (
        <Button color="orange" onClick={() => setselected(v)}>
          envoyer
        </Button>
      ),
    },
    {
      value: "id",
      name: "-",
      render: (v) => (
        <Button color="green" onClick={() => setselectedVisa(v)}>
          Visas
        </Button>
      ),
    },
  ];
  return selected ? (
    <PilgrimsSend
      groupId={selected}
      setgroup={() => {
        setselected(0);
        localStorage.setItem("stop", true);
      }}
      group={data.find((el) => el.id == selected)}
    />
  ) : selectedVisa ? (
    <Visas
      groupId={selectedVisa}
      setgroup={() => {
        setselectedVisa(0);
        localStorage.setItem("stop1", true);
      }}
      group={data.find((el) => el.id == selectedVisa)}
    />
  ) : (
    <div>
      <Filter search={() => fetch()}>
        <Responsive className="p-10">
          <label>Nom: </label>

          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        // excelData={multiDataSet(data)}
        noExport
        size="md"
        save={save}
        AddComponent={
          <AddEdit error={error} model={model} _setmodel={setmodel} />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <ReactPaginate
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
          nextLabel=">"
          onPageChange={({ selected }) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page: selected + 1 };
            });
          }}
          pageRangeDisplayed={20}
          pageCount={totalCount ? Math.ceil(totalCount / filterModel.take) : 1}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />

        <div>
          Total : <span>{totalCount}</span>
        </div>
      </div>
    </div>
  );
}

const PilgrimsSend = ({ groupId, group, setgroup }) => {
  const [pilgrims, setpilgrims] = useState([]);
  const [conf, setconf] = useRecoilState(ConfigState);
  const [stop, setstop] = useState(false);
  const [index, setindex] = useState(0);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    localStorage.setItem("stop", true);
  }, []);
  const send_recursive = async (i, els) => {
    setindex(i);

    let _st = localStorage.getItem("stop");
    console.log(_st);
    if (_st == "false") {
      let _res = await axios.post("http://localhost:8733/SetPilgrim", els[i]);
      let _el = { ...els[i] };
      if (_res.status == 200) {
        _el.codeMoufa = _res.data.codeMoufa;
        _el.errorMessage = _res.data.Erreur;
      } else {
        _el.errorMessage = "erreur";
      }
      setpilgrims((prev) => {
        let da = [...prev];
        da[i] = _el;
        return da;
      });
      APi.createAPIEndpoint(ENDPOINTS.Pilgrim)
        .update(_el.id, _el)
        .then((res) => {
          debugger;
          if (i < els.length - 1) {
            send_recursive(i + 1, els);
          } else setloading(false);
        })
        .catch((e) => {
          console.log("index: ", i);
          if (i < els.length - 1) {
            send_recursive(i + 1, els);
          } else setloading(false);
        });
    }
  };

  const fetch = () => {
    APi.createAPIEndpoint(ENDPOINTS.Pilgrim, { page: 1, take: 10000, groupId })
      .customGet()
      .then((res) =>
        setpilgrims(
          res.data.data.filter(
            (el) =>
              !el.codeMoufa &&
              el.errorMessage !=
                "هناك طلب فعال لحامل هذا الجواز. لا يمكن اضافة آخر"
          )
        )
      );
  };
  useEffect(() => {
    fetch();
    console.log(group);
  }, [groupId]);
  const send = async () => {
    localStorage.setItem("stop", false);
    setloading(true);
    let els = pilgrims.map((p) => ({
      ...p,
      login: conf.login,
      password: conf.password,
      phoneNumber: group.commonPhone,
      email: group.commonEmail,
      arrivalDate: group.arrivalDate
        ? group.arrivalDate.substring(0, 10).split("-").reverse().join("-")
        : "",
      passportDeliveryDate: p.passportDeliveryDate
        ? p.passportDeliveryDate.split("-")[0].length == 2
          ? p.passportDeliveryDate.split("-").reverse().join("-")
          : p.passportDeliveryDate
        : "",
      refNumber: group.refNumber,
    }));
    await send_recursive(0, els);
  };
  return (
    <div>
      <div style={{ textAlign: "right", padding: "10px" }}>
        <Button color="" onClick={setgroup}>
          retour
        </Button>{" "}
        <Button color="orange" onClick={send}>
          Envoyer
        </Button>{" "}
        <Button
          color="red"
          onClick={() => {
            setstop(true);
            localStorage.setItem("stop", true);
            setloading(false);
          }}
        >
          arréter
        </Button>
      </div>
      {loading && (
        <div
          style={{
            display: "flex ",
            alignItems: "center",
            border: "1px solid #eee",
            padding: "10px",
            background: "#fff",
            maxWidth: "800px",
            margin: "0 auto",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            En cours :
            <b style={{ color: "green" }}>
              {pilgrims[index].firsName + "  " + pilgrims[index].lastName}{" "}
              <br></br>
              <i style={{ color: "#444" }}>
                {pilgrims[index].firsNameAr + "  " + pilgrims[index].lastNameAr}
              </i>
            </b>
          </div>
          <img alt="...loading" src="/svg-loaders/spinning-circles.svg"></img>
        </div>
      )}
      <Grid columns={columns2} rows={pilgrims} />
    </div>
  );
};
const columns2 = [
  {
    value: "codeMoufa",
    value2: "errorMessage",
    name: "",
    render: (v, v2) => (
      <span
        style={{
          content: "",
          width: "10px",
          height: "50px",
          background: v ? "green" : v2 ? "red" : "orange",
          display: "block",
        }}
      ></span>
    ),
  },

  {
    value: "firsNameAr",
    value2: "firsName",
    value3: "lastNameAr",
    value4: "lastName",
    name: " المترشح",
    render: (v, v2, v3, v4) => (
      <b style={{ color: "green" }}>
        {v + " " + v3} <br></br>
        <i style={{ color: "#444" }}>{v2 + " " + v4}</i>
      </b>
    ),
  },

  {
    value: "passportNumber",
    name: "Passport",
    render: (v) => <b style={{ color: "green" }}>{v}</b>,
  },
  {
    value: "codeMoufa",
    value2: "errorMessage",
    name: "Resultat",
    render: (v, v2) => (
      <span
        style={{
          color: v ? "green" : v2 ? "red" : "",
        }}
      >
        {v ? v : v2 ? v2.substring(0, 30) + "..." : "-"}
      </span>
    ),
  },
];
